@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}
:root{
  --main-bg : #E4E9F7;

  --light-white: #f0f0f0;
  --light-white-p: #6c757d;
  --color-main: #2E3F97;  
  --color-main-light: #53b6fd;  
  --color-anchor: #0d6efd;
  --color-blue: #4d8aff;
  --color-dark-blue: #004269;
  --color-blu : #4387c0;
  --color-blue-light : #00AAA9;
  --color-orange : orange;
  --color-green : rgb(44, 185, 16);

  --border-color-1 : rgba(101, 119, 134, 0.36);
}

a{
  color: inherit;
  text-decoration: none;
}
ol, ul {
  padding-left: 0rem;
  margin-bottom: 0rem;
}

.breadCmb{
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}
.breadCmb h2{
  color: var(--color-main);
}
.breadCmb ul{
  display: flex;
}
.btn1 a{
  color: var(--color-main);
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid var(--color-main);
  padding: 10px 20px;
  font-size: 17px;
  font-weight: 600;
  background: transparent;
  position: relative;
  transition: all 1s;
  overflow: hidden;
  display: inline-block;
}
.btn1 a:hover{
  color: white;
  background-color: #004269;
}


.tables{
  margin: 2rem 0rem;
  background: white;
  padding: 0.5rem;
}
.tables tbody img{
  height: 75px;
  width: 75px;
  object-fit: contain;
}
.tables tbody .upd-btns{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.tables tbody .upd-btns .upd-btns{
  padding: 5px;
  color: white;
  background: red;
  border: none;
  outline: none;
}
.tables tbody .upd-btns .upd-btns.update{
  background: rgb(44, 185, 16);
}
.forms {
  margin: 2rem 0rem;
  background: white;
  padding:1rem;
}
.forms form>div{
  padding-bottom: 1rem;
}
.forms form textarea{
  height: 100px;
}


